import { createStore } from 'solid-js/store';
import { LicenseSearchResults } from '../ApiRequests';

export interface AvailableSites {
    boardId?: number;
    boardName?: string | null;
    disabled: boolean;
}

const [availableSites, setAvailableSites] = createStore<AvailableSites[]>([]);
export const resetAvailableSites = () => setAvailableSites([]);

export { availableSites, setAvailableSites };
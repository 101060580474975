/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateVerificationRequest } from '../models/CreateVerificationRequest';
import type { CreateVerificationResponse } from '../models/CreateVerificationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VerificationRouteService {

    /**
     * Creates a new verification and transaction. Responds with the transaction ID. After this step a payment is required to finish the transaction. Payment can be made by sending a POST request to https://sandbox.basysiqpro.com/api/tokenizer with a Body: {"tsid":"842d3d1d-766d-489b-b1ff-5bef70b9f5a7","payment_method":{"card":{"entry_type":"keyed","number":"378282246310005","expiration_date":"01/25","cvc":"564"}}}  The TSID must be a unique ID you either generate or re-use the Public Transaction ID in this requests response.
     * @returns CreateVerificationResponse Success
     * @throws ApiError
     */
    public static postApiV1Verifications({
requestBody,
}: {
requestBody: CreateVerificationRequest,
}): CancelablePromise<CreateVerificationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/verifications',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}

import {
  Component,
  For,
  Show,
  createEffect,
  createResource,
  createSignal,
  onMount,
} from "solid-js";
import {
  verificationsRequests,
  setVerificationsRequests,
} from "../Stores/verificationsRequestingStore";
import { availableSites } from "../Stores/availableSitesStore";
import VerificationNavigationButtons from "./verificationNavigationButtons";
import { ContentBox } from "./contentBox";
import { ApiError, VerificationRouteService } from "../ApiRequests";
import { setVerificationConfirmationStore } from "../Stores/verificationsConfirmationStore";
import Info from "./info";
import Error from "./error";
import * as Sentry from "@sentry/browser";

export interface StateBoardSelectionFormProps {
  onNext: () => void;
  onBack: () => void;
  onCancel: () => void;
}

export const StateBoardSelectionForm: Component<
  StateBoardSelectionFormProps
> = (props) => {
  let form: HTMLFormElement;
  const [processingRequestMessage, setProcessingRequestMessage] =
    createSignal("");
  const [errorMessage, setErrorMessage] = createSignal("");
  const [boardsSelected, setBoardsSelected] = createSignal(
    availableSites.length > 0 ? [availableSites[0].boardId] : []
  );

  onMount(async () => {
    // if (form.querySelector('select')) {
    //     form.querySelector('select').focus();
    // }
  });

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    if (errorMessage() != "") setError("");
    setProcessingRequestMessage(
      `Please wait, verification${
        (verificationsRequests?.verificationCount ?? 0) > 1 ? "s" : ""
      } pending review.`
    );
    const sanitizedBoardsSelected = [
      ...new Set(boardsSelected().filter((boardId) => boardId != 0)),
    ];
    setVerificationsRequests({
      licenseIds: [...verificationsRequests.licenseIds],
      sendToBoardIds: sanitizedBoardsSelected,
    });

    try {
      const response = await VerificationRouteService.postApiV1Verifications({
        requestBody: verificationsRequests,
      });
      setProcessingRequestMessage("Successfully sent verifications.");
      setVerificationConfirmationStore(response);
      props.onNext();
    } catch (error) {
      const msg =
        error?.body?.detail ??
        error?.message ??
        "An error occurred while sending verifications.";
      setError(msg);
      if (error instanceof ApiError) {
        if (error?.body?.errors && Object.keys(error.body.errors).length > 0) {
          const apiErrorMsg = Object.keys(error.body.errors)
            .map((e) =>
              Array.isArray(error.body.errors[e])
                ? error.body.errors[e].join(". ")
                : ""
            )
            .join(" ");
          setError(apiErrorMsg);
        }
        Sentry.captureException(error, {
          extra: {
            body: error.body,
            status: error.status,
            url: error.url,
            request: error.request,
          },
        });
      } else {
        Sentry.captureException(error);

        //TODO: Try again & record more detailed error. Possibly getting a TypeError: Failed to fetch here. Need to understand what data is being sent & what hte response was if any.
        // async function fetchDataWithRetry(url, options = {}, retryCount = 1) {
        //     try {
        //         let response = await fetch(url, options);

        //         // If the response is not ok (status code outside the range 200-299),
        //         // it will throw an error
        //         if (!response.ok) {
        //             throw new Error(`HTTP error! status: ${response.status}`);
        //         }

        //         let data = await response.json();
        //         return data;
        //     } catch (error) {
        //         console.log(`Failed to fetch ${url}`);
        //         console.log(`Error message: ${error.message}`);

        //         if (retryCount > 0) {
        //             console.log("Retrying fetch...");
        //             return fetchDataWithRetry(url, options, retryCount - 1);
        //         } else {
        //             console.log("Failed to fetch after 1 retry. Giving up.");
        //             throw error; // rethrow the error so it can be handled upstream if needed
        //         }
        //     }
        // }

        // fetchDataWithRetry('https://api.example.com/data')
        //     .then(data => console.log(data))
        //     .catch(error => console.error('Final error:', error));
      }
    }
  };

  const setError = (error: string) => {
    setErrorMessage(error);
    setProcessingRequestMessage("");
  };

  const updateBoardsSelected = (boardSelectedIndex: number, value: string) => {
    const numberValue = Number.parseInt(value) || 0;
    setBoardsSelected(
      boardsSelected().map((boardId, index) =>
        index == boardSelectedIndex ? numberValue : boardId
      )
    );
  };

  const handleAddBoardDropDown = () => {
    setBoardsSelected([...boardsSelected(), availableSites[0].boardId]);
  };

  const handleRemoveBoardDropDown = (index: number) => {
    setBoardsSelected(boardsSelected().filter((_, i) => i != index));
  };

  return (
    <div>
      <h1 class="text-4xl">Send Verification To?</h1>
      <ContentBox>
        <Show when={availableSites.length > 0}>
          <p class="text-left font-bold">
            {verificationsRequests?.verificationCount ?? 0} verification
            {(verificationsRequests?.verificationCount ?? 0) > 1
              ? "s have"
              : " has"}{" "}
            been selected to send.
          </p>
          <ul class="text-left list-disc list-inside ml-4 italic">
            <li>
              VeriDoc may be used to send verification of licenses to any of the
              licensure boards contained below.
            </li>
            <li>
              If a board is marked 'unavailable', please go back to Step 2 and
              de-select the license issued by that board. Our system does not
              allow you to send verification from the verifying board to the
              verifying board.
            </li>
          </ul>

          <div class="text-left font-bold mt-8 mb-4">
            Choose the state board which you would like to receive the
            verifications.
          </div>
          <form onSubmit={handleSubmit} ref={form}>
            <For each={boardsSelected().length == 0 ? [0] : boardsSelected()}>
              {(board, boardIndex) => (
                <div class="text-left">
                  <select
                    title="Select a state board to send the verifications to."
                    onChange={(e) =>
                      updateBoardsSelected(
                        boardIndex(),
                        (e.target as HTMLSelectElement).value
                      )
                    }
                    class="bg-white border-solid border-2 border-gray-300 rounded mb-1"
                  >
                    <For each={availableSites}>
                      {(state) => (
                        <option
                          value={state.boardId}
                          selected={state.boardId == board}
                          disabled={state.disabled}
                        >
                          {state.boardName}
                        </option>
                      )}
                    </For>
                  </select>
                  <Show when={boardsSelected().length > 1}>
                    <button
                      class="bg-red-800 border-red-900 hover:bg-red-900 border-2 ml-4 px-2 rounded-md text-white text-2xl  w-12"
                      title="Remove Board"
                      type="button"
                      onClick={() => handleRemoveBoardDropDown(boardIndex())}
                    >
                      {" "}
                      -
                    </button>
                  </Show>
                  <button
                    class={`border-2 ml-4 px-3 rounded-md text-white text-2xl w-12 bg-sky-700 border-sky-800 hover:bg-sky-800`}
                    title="Add Board"
                    type="button"
                    onClick={handleAddBoardDropDown}
                  >
                    +
                  </button>
                </div>
              )}
            </For>

            {/* JoDee preferred the drop down. Leaving this in case we need to go back to checkboxes for simplicity. 2023-05-05 */}
            {/* <For each={availableSites}>
                        {(item) => {
                            return (
                                <div class='text-left'>
                                    <label class={`${item.disabled && 'opacity-30 line-through'} py-2 hover:bg-sky-200 px-4 w-full cursor-pointer`}>
                                        <input
                                            type="checkbox"
                                            class="form-checkbox"
                                            value={item.boardId}
                                            disabled={item.disabled || (maxStatesReached() && !verificationsRequests.sendToBoardIds.includes(item.boardId))}
                                            onChange={() => toggleState()}
                                            checked={verificationsRequests.sendToBoardIds.includes(item.boardId)}
                                        />
                                        <span class="ml-2 pt-2">{item.boardName}</span>
                                    </label>
                                </div>
                            )
                        }}
                    </For> */}

            <Show when={errorMessage()}>
              <Error message={errorMessage()} />
            </Show>
            <Show when={processingRequestMessage()}>
              <Info message={processingRequestMessage()} />
            </Show>

            <VerificationNavigationButtons
              onSubmit={handleSubmit}
              onBack={props.onBack}
              onCancel={props.onCancel}
              onSubmitDisabled={
                boardsSelected().filter((b) => b != 0).length < 1
              }
            />
          </form>
        </Show>

        <Show when={availableSites.length == 0}>
          <p class="text-left font-bold">
            No boards are available to send verifications to for this license
            type. We are in the process of adding boards. Please check back
            soon.
          </p>
        </Show>
      </ContentBox>
    </div>
  );
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactRequest } from '../models/ContactRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContactRouteService {

    /**
     * Add Contact request to e-mail queue
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiV1Contact({
requestBody,
}: {
requestBody: ContactRequest,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}

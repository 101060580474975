import {
  Component,
  For,
  Show,
  createEffect,
  createResource,
  createSignal,
  onMount,
} from "solid-js";
import { searchResults } from "../Stores/searchResultsStore";
import {
  AcceptedForMasterLicenseTypeResults,
  ApiError,
  CreateVerificationRequest,
  LicenseTypeRouteService,
  MasterLicenseTypeResults,
  VerificationRouteService,
} from "../ApiRequests";
import { formatShortDate } from "../Utilities/date";
import { createStore } from "solid-js/store";
import { setVerificationsRequests } from "../Stores/verificationsRequestingStore";
import {
  AvailableSites,
  availableSites,
  setAvailableSites,
} from "../Stores/availableSitesStore";
import VerificationNavigationButtons from "./verificationNavigationButtons";
import { searchRequestStore } from "../Stores/searchRequestStore";
import { ContentBox } from "./contentBox";
import {
  resultsSelected,
  setResultsSelected,
} from "../Stores/resultsSelectedStore";
import * as Sentry from "@sentry/browser";

export interface ResultsListProps {
  onNext: () => void;
  onBack: () => void;
  onCancel: () => void;
}

export const ResultsList: Component<ResultsListProps> = (props) => {
  const toggleLicense = (
    licenseId: string,
    e: MouseEvent & {
      currentTarget: HTMLInputElement | HTMLTableRowElement;
      target: Element;
    }
  ) => {
    e.stopPropagation();

    //Sanitize & make sure we don't get left overs from previous searches
    const sanitizedResults = [...resultsSelected].filter((x) =>
      searchResults.find((y) => y.publicId == x)
    );

    if (e.currentTarget instanceof HTMLInputElement) {
      if (e.currentTarget.checked) {
        setResultsSelected([...sanitizedResults, licenseId]);
      } else {
        setResultsSelected(
          sanitizedResults.filter((item) => item !== licenseId)
        );
      }
    } else {
      if (e.currentTarget.querySelector("input").checked) {
        setResultsSelected(
          sanitizedResults.filter((item) => item !== licenseId)
        );
      } else {
        setResultsSelected([...sanitizedResults, licenseId]);
      }
    }
  };

  const getAcceptedLicenseTypes = async (
    selectedBoards: number[],
    forceRefresh: boolean
  ) => {
    const masterLicenseTypeId = searchResults[0].masterLicenseTypeId;
    const updateAcceptedStates = (
      state: AcceptedForMasterLicenseTypeResults
    ): AvailableSites => ({
      boardId: state.boardId,
      boardName: state.boardName,
      disabled:
        selectedBoards.length > 0 && selectedBoards.includes(state.boardId),
    });

    if (availableSites.length === 0 || forceRefresh) {
      const licenseAcceptedStates =
        await LicenseTypeRouteService.getApiV1LicenseTypesAcceptedForMasterLicenseType(
          { masterLicenseTypeId }
        );
      setAvailableSites([
        { boardId: 0, boardName: "Select a State", disabled: true },
        ...licenseAcceptedStates.map(updateAcceptedStates),
      ]);
    } else {
      setAvailableSites(availableSites.map(updateAcceptedStates));
    }
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    if (searchResults.length > 0) {
      try {
        //count the number of boards sending from - Each verification is a unique board with 1 or many licenses
        const verificationCount = new Set(
          searchResults
            .filter((item) => resultsSelected.includes(item.publicId))
            .map((item) => item.stateBoardId)
        ).size;
        setVerificationsRequests({
          licenseIds: resultsSelected,
          sendToBoardIds: [],
          verificationCount,
        });
        const selectedBoards = searchResults
          .filter((item) => resultsSelected.includes(item.publicId))
          .map((item) => item.stateBoardId);
        await getAcceptedLicenseTypes(selectedBoards, false);
        props.onNext();
      } catch (error) {
        if (error instanceof ApiError) {
          Sentry.captureException(error, {
            extra: {
              body: error.body,
              status: error.status,
              url: error.url,
              request: error.request,
            },
          });
        } else {
          Sentry.captureException(error);
        }
      }
    }
  };

  let form: HTMLFormElement;

  onMount(async () => {
    if (form.querySelector("input")) {
      form.querySelector("input").focus();
      await getAcceptedLicenseTypes([], true);
    }
    if (resultsSelected.length === 0) {
      setResultsSelected(searchResults.map((item) => item.publicId));
    }
  });

  return (
    <div>
      <h1 class="text-4xl">Select Licenses</h1>
      <ContentBox>
        <form onSubmit={handleSubmit} ref={form}>
          <div class="mb-12 text-left">
            <div>
              Licenses for:{" "}
              <strong>{searchRequestStore.lastName.join(", ")}</strong>
            </div>
            <div class="pb-4">
              Date of Birth: <strong>{searchRequestStore.dateOfBirth}</strong>
            </div>
            <p class="italic">
              <strong>NOTE: </strong> Review search results and{" "}
              <strong>
                de-select any license(s) that do not pertain to you
              </strong>{" "}
              or that you do not want verified to a state licensing board.
            </p>
          </div>

          <Show when={searchResults.length === 0}>
            <p class="text-red-700 text-2xl text-left">
              No license information found.
            </p>
          </Show>
          <Show when={searchResults.length > 0}>
            <div class="overflow-x-auto">
              <table class="w-full text-xl table-auto">
                <thead>
                  <tr class="w-full border-b-2 border-b-gray-300 leading-10">
                    <th class="text-center pr-2">Select</th>
                    <th class="text-center pr-2">State</th>
                    <th class="text-left pr-2">Name</th>
                    <th class="text-left">Address</th>
                    <th class="whitespace-nowrap">License #</th>
                    <th>Status</th>
                    <th>Issued</th>
                    <th>Expires</th>
                  </tr>
                </thead>
                <tbody>
                  <For each={searchResults}>
                    {(item, i) => (
                      <tr
                        class="border-b my-2 border-b-gray-300 cursor-pointer  hover:bg-sky-200"
                        onClick={(e) => {
                          toggleLicense(item.publicId, e);
                        }}
                      >
                        <td class="text-center py-2">
                          <input
                            title="Public Id"
                            id={`public_id_${i()}`}
                            name={`public_id_${i()}`}
                            type="checkbox"
                            class=""
                            value={item.publicId}
                            onClick={(e) => toggleLicense(item.publicId, e)}
                            checked={resultsSelected.includes(item.publicId)}
                          />
                        </td>
                        <td class="text-center">{item.stateBoardInitials}</td>
                        <td class="text-left">
                          {item.firstName} {item.lastName}
                        </td>
                        {/* Display item address separated by commas when not null or empty string */}
                        <td class="text-left">
                          {item.address && item.address + ", "}
                          {item.city && item.city + ", "}
                          {item.state && item.state + " "}
                          {item.zip}
                        </td>
                        <td class="text-left">{item.licenseNumber}</td>
                        <td class="text-left">{item.status}</td>
                        <td class="text-left">
                          {formatShortDate(item.issued)}
                        </td>
                        <td class="text-left">
                          {formatShortDate(item.expires)}
                        </td>
                      </tr>
                    )}
                  </For>
                </tbody>
              </table>
            </div>
          </Show>
          <VerificationNavigationButtons
            onSubmit={handleSubmit}
            onSubmitDisabled={
              searchResults.length === 0 || resultsSelected.length === 0
            }
            onBack={props.onBack}
            onCancel={props.onCancel}
          />
        </form>
      </ContentBox>
    </div>
  );
};

export default ResultsList;
